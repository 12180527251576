import { combineReducers } from "redux";
import {
  GET_WECHAT_SETTINGS,
  USER_INFO,
  EVENTS_LIST_NAV_BUTTON_STATUS,
  SEARCH_KEY_WORD,
  SEARCH_CONFIG,
  REGISTRATION_PAGE_REDIRECT_URL,
  CURRENT_MEDIA_RESOURCE_INFO,
  NO_CHANGED_MEDIA_GROUP_INFO
} from "./states";

let wechatSettings = {};
let userInfo = {};
let eventsListNavButtonStatus = {
  online: false,
  offline: false
};
let searchKeyWord = "";
let searchConfig = {
  hasSearch: false,
  searchType: '',
};
let registrationPageRedirectUrl = "";
let currentMediaResourceInfo = {
  assetId: 0,
  currIndex: -1,
  type: ""
};
let noChangedMediaGroupInfo = {
  groupId: 0,
  groupUrl: ""
};

// Sets wechatSettings
const setWeChatSettings = (state = wechatSettings, actions) => {
  switch (actions.type) {
    case GET_WECHAT_SETTINGS:
      return actions.payload;
    default:
      return state;
  }
};

// Sets user information
const getUserInfo = (state = userInfo, actions) => {
  switch (actions.type) {
    case USER_INFO:
      return actions.payload;
    default:
      return state;
  }
};

// Sets the status of the button menu at the top of the events list page
const setEvnetsListNavButtonStatus = (
  state = eventsListNavButtonStatus,
  actions
) => {
  switch (actions.type) {
    case EVENTS_LIST_NAV_BUTTON_STATUS:
      return actions.payload;
    default:
      return state;
  }
};

// Sets search keywords
const setSearchKeyWord = (state = searchKeyWord, actions) => {
  switch (actions.type) {
    case SEARCH_KEY_WORD:
      return actions.payload;
    default:
      return state;
  }
};

// Set search config
const setSearchConfig = (state = searchConfig, actions) => {
  switch (actions.type) {
    case SEARCH_CONFIG:
      return actions.payload;
    default:
      return state;
  }
};

// Before redirecting an unregistered user to the registration page, record the url before redirecting
const setRegistrationPageRedirectUrl = (
  state = registrationPageRedirectUrl,
  actions
) => {
  switch (actions.type) {
    case REGISTRATION_PAGE_REDIRECT_URL:
      return actions.payload;
    default:
      return state;
  }
};

// Sets the resource information that the user is currently browsing
const setCurrentMediaResourceInfo = (
  state = currentMediaResourceInfo,
  actions
) => {
  switch (actions.type) {
    case CURRENT_MEDIA_RESOURCE_INFO:
      return actions.payload;
    default:
      return state;
  }
};

// When a non-redeemable user enters the asset detail page directly, record the url of the current detail page
const setNoChangedMediaGroupInfo = (
  state = noChangedMediaGroupInfo,
  actions
) => {
  switch (actions.type) {
    case NO_CHANGED_MEDIA_GROUP_INFO:
      return actions.payload;
    default:
      return state;
  }
};

// Merger of reducer
const reducer = combineReducers({
  wechatSettings: setWeChatSettings,
  getUserInfo: getUserInfo,
  eventsListNavButtonStatus: setEvnetsListNavButtonStatus,
  searchKeyWord: setSearchKeyWord,
  searchConfig: setSearchConfig,
  registrationPageRedirectUrl: setRegistrationPageRedirectUrl,
  currentMediaResourceInfo: setCurrentMediaResourceInfo,
  noChangedMediaGroupInfo: setNoChangedMediaGroupInfo
});

export default reducer;
