import axios from "../axios";

export function _mediaFreeSampleGotJudgement(id) {
  return axios.get(`/oup_user/is_apply/free_sample_applicant/${id}`);
}

export function _mediaFreeSampleSource(contentId, assetId) {
  return axios.get(`/free_sample/asset_detail/${contentId}/${assetId}`);
}

export function _mediaFreeSampleAudioSourceList(id) {
  return axios.get(`/free_sample/asset_list/${id}/mp3`);
}

export function _mediaEducationReportPDFSource(id) {
  return axios.get(`/jsonapi/file/file/${id}`);
}

export function _mediaExchangeSources(params) {
  return axios.post(`/oup_redeem/exchanges`, params);
}

export function _mediaMyRedeemList(page = 0, pageSize = 10, free = 1) {
  return axios.get(`/oup_redeem/exchanges?page=${page}&size=${pageSize}&free=${free}`);
}

export function _mediaGroupDetails(id, code) {
  return axios.get(`/asset_group/${id}${!!code ? `?code=${code}` : ""}`);
}

export function _mediaGroupAssetsList(id, type, name) {
  return axios.get(
    `/asset_group/asset_list/${id}/${type}${!!name ? `?name=${name}` : ""}`
  );
}

export function _mediaAssetDetails(groupId, id) {
  return axios.get(
    `/asset_group/asset_detail/${groupId}/${id}`
  );
}

export function _mediaAssetZipDownload(id, groupId) {
  return axios.get(
    `/asset_zip/download/${id}?asset_group=${groupId}`
  );
}