/**
 * Bottom navigation menu.
 */
import React, { Component } from "react";
import { _blockCopyRight } from "@/apis";
import "./Footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  getCopyright = async () => {
    await _blockCopyRight().then(res => {
      if (this.isMount) {
        this.setState({
          data: res.data.data[0].attributes.body
        });
      }
    });
  };

  componentDidMount() {
    this.isMount = true;
    this.getCopyright();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  render() {
    return (
      <footer>
        <div className="footer">
          <div dangerouslySetInnerHTML={{ __html: this.state.data }} />
        </div>
      </footer>
    );
  }
}

export default Footer;
