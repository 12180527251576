import axios from "../axios";

export async function _advertisementList(pageIndex) {
  return await axios.get(
    `/jsonapi/node/advertisement?fields[node--advertisement]=title,field_image,field_link,field_baidustatistics&include=field_image&jsonapi_include=1&fields[file--file]=uri&filter[field_pages]=${pageIndex}&sort=field_weight,-created`
  );
}

export async function _advertisementMediaGroup(uuid) {
  return await axios.get(
    `/jsonapi/node/asset_group/${uuid}?include=field_advertisement,field_advertisement.field_image&jsonapi_include=1&fields[file--file]=uri&fields[node--advertisement]=field_image,field_link,field_baidustatistics&fields[node--asset_group]=title,field_advertisement`
  );
}
