import axios from "../axios";

export function _detailsToolsAddBookMark(params) {
  return axios.post("/oup_user/add_bookmark", params);
}

export function _detailsToolsBookMarkStatus(id, uuid) {
  return axios.get(`/oup_user/select_bookmark?id=${id}&uuid=${uuid}`);
}

export function _detailsToolsCommit(params) {
  return axios.post("/comment", params);
}
