import axios from "../axios";

export async function _carouselProductsCenter() {
  return await axios.get(
    "/jsonapi/node/carousel?fields[node--carousel]=title,field_link,field_carousel_video,field_carousel_image&include=field_carousel_image&jsonapi_include=1&fields[file--file]=uri&sort=field_weight,-created"
  );
}

export async function _carouselEvents() {
  return await axios.get(
    "/jsonapi/node/event_carousel?fields[file--file]=uri&fields[node--event_carousel]=title,field_carousel_image,field_event&filter[field_event.status]=1&include=field_carousel_image&jsonapi_include=1&sort=field_weight,-created&filter[status]=1"
  );
}

export async function _carouselSolutionsList() {
  return await axios.get(
    "/jsonapi/course_solutions?fields[file--file]=uri&jsonapi_include=1&sort=weight,-created&fields[course_solutions]=title,summary,banner,background,field_add_pd_solution_link&filter[or][group][conjunction]=OR&filter[display-filter][condition][path]=field_hidden_from_display_list&filter[display-filter][condition][value]=1&filter[display-filter][condition][operator]=<>&filter[display-filter][condition][memberOf]=or&filter[display-filter-null][condition][path]=field_hidden_from_display_list&filter[display-filter-null][condition][operator]=IS NULL&filter[display-filter-null][condition][memberOf]=or"
  );
}
