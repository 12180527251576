import {
  GET_WECHAT_SETTINGS,
  USER_INFO,
  EVENTS_LIST_NAV_BUTTON_STATUS,
  SEARCH_KEY_WORD,
  SEARCH_CONFIG,
  REGISTRATION_PAGE_REDIRECT_URL,
  CURRENT_MEDIA_RESOURCE_INFO,
  NO_CHANGED_MEDIA_GROUP_INFO
} from "./states";

// Gest wechat settings
export function getWeChatSettings(payload) {
  return dispatch => {
    dispatch({
      type: GET_WECHAT_SETTINGS,
      payload: payload
    });
  };
}
// Gets user information
export function getUserInfo(payload) {
  return dispatch => {
    dispatch({
      type: USER_INFO,
      payload: payload
    });
  };
}

// Sets the status of the button menu at the top of the events list page action
export function setEvnetsListNavButtonStatus(payload) {
  return dispatch => {
    dispatch({
      type: EVENTS_LIST_NAV_BUTTON_STATUS,
      payload: payload
    });
  };
}

// Sets search keywords
export function setSearchKeyWord(payload) {
  return dispatch => {
    dispatch({
      type: SEARCH_KEY_WORD,
      payload: payload
    });
  };
}

// Set search config
export function setSearchConfig(payload) {
  return dispatch => {
    dispatch({
      type: SEARCH_CONFIG,
      payload: payload
    });
  };
}

// Before redirecting an unregistered user to the registration page, record the url before redirecting
export function registrationPageRedirectUrl(payload) {
  return dispatch => {
    dispatch({
      type: REGISTRATION_PAGE_REDIRECT_URL,
      payload: payload
    });
  };
}

// Sets the resource information that the user is currently browsing
export function currentMediaResourceInfo(payload) {
  return dispatch => {
    dispatch({
      type: CURRENT_MEDIA_RESOURCE_INFO,
      payload: payload
    });
  };
}

// When a non-redeemable user enters the asset detail page directly, record the url of the current detail page
export function noChangedMediaGroupInfo(payload) {
  return dispatch => {
    dispatch({
      type: NO_CHANGED_MEDIA_GROUP_INFO,
      payload: payload
    });
  };
}
