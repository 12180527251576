/**
 * Configure the higher-order components of axios before the page loads.
 */
import React, { Component } from "react";
import Loading from "@/components/Loading/Loading";
import intl from "react-intl-universal";

// locale data
const locales = {
  "zh-CN": require("@/assets/languages/CN.json"),
  "en-US": require("@/assets/languages/US.json")
};
export default WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        initDone: false,
        loading: locales["zh-CN"].LOADING      
      };
    }
    loadLocales() {
      // React-intl-universal is a singleton and should only be instantiated once
      intl
        .init({
          currentLocale: "zh-CN",
          locales
        })
        .then(() => {
          this.setState({ initDone: true });
        });
    }
    componentDidMount() {
      this.isMount = true;
      this.loadLocales();
    }
    componentWillUnmount() {
      this.isMount = false;
    }
    render() {
      return (
        <div>
          {this.state.initDone ? (
            <div>
              <WrappedComponent {...this.props} />
            </div>
          ) : (
            <Loading desc={this.state.loading} />
          )}
        </div>
      );
    }
  };
};
