import axios from "../axios";

export function _formBanner(index) {
  return axios.get(
    `/jsonapi/block_content/simple_block?filter[field_position]=${index}&fields[block_content--simple_block]=body`
  );
}

export function _formSendSmsCode(params) {
  return axios.post("/sms/aliyun/verify_send", params);
}

export function _formRegistrationSubmit(params) {
  return axios.post("/oup_user/register", params);
}

export function _formEventsSubmit(params) {
  return axios.post("/oup_event/registration", params);
}

export function _formFreeSampleSubmit(params) {
  return axios.post("/oup_user/apply", params);
}

export function _formContactSubmit(params) {
  return axios.post("/oup_user/contact_us", params);
}

export async function _formProvince() {
  return await axios.get("/category/province");
}

export function _getFormField(id) {
  return axios.get(`/oup_event/applicant_form/${id}`);
}
