/**
 * A loading UI component.
 */
import React, { Component } from "react";
import { Icon } from "antd-mobile";
import intl from "react-intl-universal";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let backgroundColor = this.props.backgroundColor
      ? this.props.backgroundColor
      : "#173865";
    let desc = !!this.props.desc ? this.props.desc : intl.get("LOADING");
    return (
      <div
        style={{
          width: "100vw",
          height:
            !!this.props.height && this.props.height !== ""
              ? this.props.height
              : "100vh",
          backgroundColor: backgroundColor,
          zIndex: 99,
          overflow: "hidden"
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "0 25%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <Icon
            type="loading"
            size="large"
            style={{ width: "1rem", height: "1rem" }}
          />
          <p
            style={{ marginTop: "0.5rem", fontSize: "0.32rem", color: "#fff" }}
          >
            {desc}
          </p>
        </div>
      </div>
    );
  }
}

export default Loading;
