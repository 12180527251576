import axios from "../axios";

export async function _listEvents(time, filter) {
  return await axios.get(
    `/jsonapi/node/upcoming_event?include=field_thumbnail&jsonapi_include=1&sort=-field_event_start_date,field_weight,-created&fields[file--file]=uri&filter[recent][condition][path]=field_event_end_date&filter[recent][condition][operator]=%3C&filter[recent][condition][value]=${time}${filter}`
  );
}

export async function _listEventsNew(time, filter) {
  return await axios.get(
    `/jsonapi/node/upcoming_event?include=field_thumbnail&jsonapi_include=1&sort=-field_event_start_date,field_weight,-created&fields[file--file]=uri&filter[recent][condition][path]=field_event_end_date&filter[recent][condition][operator]=%3E%3D&filter[recent][condition][value]=${time}${filter}`
  );
}

export async function _listEducationReportList(pageSize, offset) {
  return await axios.get(
    `/jsonapi/node/education_report?include=background&fields[node--education_report]=title,background,summary&jsonapi_include=1&page[limit]=${pageSize}&page[offset]=${offset}&fields[file--file]=uri&sort=field_weight,-nid`
  );
}

export async function _listSolutionsList() {
  return await axios.get(
    "jsonapi/course_solutions?fields[file--file]=uri&jsonapi_include=1&sort=weight,-created&fields[course_solutions]=title,summary,banner,background,field_add_pd_solution_link&filter[or][group][conjunction]=OR&filter[display-filter][condition][path]=field_hidden_from_display_list&filter[display-filter][condition][value]=1&filter[display-filter][condition][operator]=<>&filter[display-filter][condition][memberOf]=or&filter[display-filter-null][condition][path]=field_hidden_from_display_list&filter[display-filter-null][condition][operator]=IS NULL&filter[display-filter-null][condition][memberOf]=or"
  );
}

export async function _listEducationResourcesFirstLevelCategoryProductsList(
  name,
  pageSize,
  offset
) {
  return await axios.get(
    `/jsonapi/node/product?include=field_category,field_thumbnail&jsonapi_include=1&fields[node--product]=title,field_category,field_thumbnail,field_ending_age,field_starting_age&sort=field_weight,-created&filter[field_category.parent.name]=${name}&page[limit]=${pageSize}&page[offset]=${offset}`
  );
}

export async function _listEducationResourcesAllCategoryProductsList(
  name,
  pageSize,
  offset
) {
  return await axios.get(
    `/jsonapi/node/product?include=field_category,field_thumbnail&jsonapi_include=1&fields[node--product]=title,field_category,field_thumbnail,field_ending_age,field_starting_age&sort=field_weight,-created&filter[field_category.name]=${name}&page[limit]=${pageSize}&page[offset]=${offset}`
  );
}

export async function _listFreeSampleList(drupalNid, currentCategoryId) {
  return await axios.get(
    `/free_samples/node/${drupalNid}/${currentCategoryId}`
  );
}

export async function _listSupportingResourcesList(drupalNid, id) {
  return await axios.get(`/support_resources/node/${drupalNid}/${id}`);
}

export async function _listJoinOupList() {
  return await axios.get("/jsonapi/node/officer?sort=field_weight,-created");
}

export async function _listHomeLearningList(categoryName, size, offset) {
  return await axios.get(
    `/jsonapi/node/learning_home?include=field_category,field_thumbnail&jsonapi_include=1&sort=-created&filter[field_category.name]=${categoryName}&page[limit]=${size}&page[offset]=${offset}`
  );
}

export async function _getMyEventsListApi() {
  return await axios.get('/my-event');
}
