import axios from "../axios";

export function _tabsEducationResourcesAllMenu() {
  return axios.get("/jsonapi/taxonomy_term/products_category?sort=weight");
}

export function _tabsEducationResourcesSecondMenu() {
  return axios.get(
    "/jsonapi/taxonomy_term/products_category?filter[taxonomy_term--products_category][condition][path]=parent.id&filter[taxonomy_term--products_category][condition][operator]=IS%20NOT%20NULL&sort=parent.weight,weight"
  );
}

export function _tabsFreeSampleCategory() {
  return axios.get("/category/free_samples_category");
}

export function _tabsSupportingResourcesCategory() {
  return axios.get("/category/support_resources_category");
}

export function _tabsHomeLearningCategory() {
  return axios.get("/jsonapi/taxonomy_term/learning_home?sort=weight");
}