/**
 * @description Bottom menu navigation bar.
 */
import React, { Component } from "react";
import "./BottomNavigation.scss";
import { NavLink } from "react-router-dom";
import intl from "react-intl-universal";

class BottomNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navs: [
        {
          path: "/products-center",
          name: intl.get("PRODUCT_CENTER")
        },
        {
          path: "/media-center",
          name: intl.get("MEDIA_CENTER")
        },
        {
          path: "/certificate-exchange",
          name: intl.get("CERTIFICATES_EXCHANGE")
        },
        {
          path: "/events",
          name: intl.get("UPCOMING_EVENTS")
        }
      ]
    };
  }
  pathnameEvent = (match, location) => {
    if (location.pathname.indexOf("/events") >= 0) {
      if (match && match.url === "/events") {
        return true;
      }
    } else if (location.pathname.indexOf("/media-center") >= 0) {
      if (match && match.url === "/media-center") {
        return true;
      }
    } else if (match) {
      return true;
    }
  };

  componentDidMount() {
    this.isMount = true;
  }

  componentWillUnmount() {
    this.isMount = false;
  }
  render() {
    return (
      <div>
        <div style={{ width: "100%", height: "1.12rem" }}></div>
        <nav>
          {this.state.navs.map((item, i) => {
            return (
              <div key={i} index={i} className="navigation">
                <NavLink to={item.path} isActive={this.pathnameEvent}>
                  {item.name}
                </NavLink>
              </div>
            );
          })}
        </nav>
      </div>
    );
  }
}

export default BottomNavigation;
