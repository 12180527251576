import React, { Component } from "react";
import { HashRouter as Router } from "react-router-dom";
import Routers from "@/router/routers";
import { Provider } from "react-redux";
import store from "@/store";
import AxiosInterceptorsHoc from "@/components/AxiosInterceptorsHoc";
import "./assets/styles/drupal-ckeditor-template.scss";
import LocalesLanguageSettings from '@/components/LocalesLanguageSettings'

// multi-language solution configuration
@LocalesLanguageSettings
// WeChat logs in and intercepts axios requests for unified processing.
@AxiosInterceptorsHoc
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Routers />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
