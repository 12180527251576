/**
 * The reserved module for unified interface management
 */
import {
  _authorizationWeChatSettings,
  _authorizationUserInfo,
  _authorizationWeChatAuthorization,
  _authorizationToken,
  _authorizationWeChatShare,
  _authorizationTemp,
} from "./authorization/authorization";
import {
  _carouselProductsCenter,
  _carouselEvents,
  _carouselSolutionsList,
} from "./carousel/carousel";
import {
  _formBanner,
  _formSendSmsCode,
  _formRegistrationSubmit,
  _formEventsSubmit,
  _formFreeSampleSubmit,
  _formContactSubmit,
  _formProvince,
  _getFormField,
} from "./form/form";
import {
  _getMyEventsListApi,
  _listEventsNew,
  _listEvents,
  _listEducationReportList,
  _listSolutionsList,
  _listEducationResourcesFirstLevelCategoryProductsList,
  _listEducationResourcesAllCategoryProductsList,
  _listFreeSampleList,
  _listSupportingResourcesList,
  _listJoinOupList,
  _listHomeLearningList,
} from "./list/list";
import { _bannerBanner } from "./banner/banner";
import {
  _detailsEducationReport,
  _detailsEducationReportAsset,
  _detailsEducationResources,
  _detailsEvents,
  _detailsEventsApplyJudgement,
  _detailsSolutions,
  _detailsSupportingResources,
  _detailsJoinOup,
  _detailsHomeLearning,
  _detailsHomeLearningRead,
} from "./details/details";
import {
  _interestedSolutionsDetails,
  _interestedEducationResourcesDetails,
} from "./interested/interested";
import {
  _mediaFreeSampleGotJudgement,
  _mediaFreeSampleSource,
  _mediaFreeSampleAudioSourceList,
  _mediaEducationReportPDFSource,
  _mediaExchangeSources,
  _mediaMyRedeemList,
  _mediaGroupDetails,
  _mediaGroupAssetsList,
  _mediaAssetDetails,
  _mediaAssetZipDownload,
} from "./media/media";
import {
  _advertisementList,
  _advertisementMediaGroup,
} from "./advertisement/advertisement";
import {
  _tabsEducationResourcesAllMenu,
  _tabsEducationResourcesSecondMenu,
  _tabsFreeSampleCategory,
  _tabsSupportingResourcesCategory,
  _tabsHomeLearningCategory,
} from "./tabs/tabs";
import {
  _blockCopyRight,
  _blockContactOup,
  _blockHistory,
  _blockDownloadInstructiony,
} from "./block/block";
import { _searchHotSearchKeyWords, _searchResultData } from "./search/search";
import { _textTopMenuButton } from "./text/text";
import {
  _detailsToolsAddBookMark,
  _detailsToolsBookMarkStatus,
  _detailsToolsCommit,
} from "./detailsTools/detailsTools";
import {
  _oupUserInfo,
  _myBookmarkSolutions,
  _myBookmarkProduct,
  _myMessages,
  _baiduKey,
} from "./user/user";

import {
  _getMyCertificatesListAPI,
  _getCertificateDetail,
  _certificateSearch,
  _bindCertificate,
  _unbindCertificate,
  _certificateDownload,
} from "./certificate/certificate";

import { _linkApplyNow } from "./extLinks/extLinks";

import {
  _getQuizes,
  _quizesFinish,
} from "./answer/answer";

export {
  _authorizationWeChatSettings,
  _authorizationUserInfo,
  _authorizationWeChatAuthorization,
  _authorizationToken,
  _authorizationWeChatShare,
  _authorizationTemp,
  _carouselProductsCenter,
  _carouselEvents,
  _carouselSolutionsList,
  _formBanner,
  _formSendSmsCode,
  _formRegistrationSubmit,
  _formEventsSubmit,
  _formFreeSampleSubmit,
  _formContactSubmit,
  _formProvince,
  _getFormField,
  _getMyEventsListApi,
  _listEvents,
  _listEventsNew,
  _listEducationReportList,
  _listSolutionsList,
  _listEducationResourcesFirstLevelCategoryProductsList,
  _listEducationResourcesAllCategoryProductsList,
  _listFreeSampleList,
  _listSupportingResourcesList,
  _listJoinOupList,
  _listHomeLearningList,
  _bannerBanner,
  _detailsEducationReport,
  _detailsEducationReportAsset,
  _detailsEducationResources,
  _detailsEvents,
  _detailsEventsApplyJudgement,
  _detailsSolutions,
  _detailsSupportingResources,
  _detailsJoinOup,
  _detailsHomeLearning,
  _detailsHomeLearningRead,
  _interestedSolutionsDetails,
  _interestedEducationResourcesDetails,
  _mediaFreeSampleGotJudgement,
  _mediaFreeSampleSource,
  _mediaFreeSampleAudioSourceList,
  _mediaEducationReportPDFSource,
  _mediaExchangeSources,
  _mediaMyRedeemList,
  _mediaGroupDetails,
  _mediaGroupAssetsList,
  _mediaAssetDetails,
  _mediaAssetZipDownload,
  _advertisementList,
  _advertisementMediaGroup,
  _tabsEducationResourcesAllMenu,
  _tabsEducationResourcesSecondMenu,
  _tabsFreeSampleCategory,
  _tabsSupportingResourcesCategory,
  _tabsHomeLearningCategory,
  _blockCopyRight,
  _blockContactOup,
  _blockHistory,
  _blockDownloadInstructiony,
  _searchHotSearchKeyWords,
  _searchResultData,
  _textTopMenuButton,
  _detailsToolsAddBookMark,
  _detailsToolsBookMarkStatus,
  _detailsToolsCommit,
  _oupUserInfo,
  _myBookmarkSolutions,
  _myBookmarkProduct,
  _myMessages,
  _baiduKey,
  _linkApplyNow,
  _getMyCertificatesListAPI,
  _getCertificateDetail,
  _certificateSearch,
  _bindCertificate,
  _unbindCertificate,
  _certificateDownload,
  _getQuizes,
  _quizesFinish,
};
