import axios from "../axios";

export function _oupUserInfo(qrst) {
  let url = '/oup_user/info?type=wechat'; 
  if (qrst) {
    url = url + '&qr_st=' + qrst;
  }
  return axios.get(url);
}

export function _myBookmarkSolutions(uid) {
  return axios.get(`/my_bookmark/course_solutions/${uid}`);
}

export function _myBookmarkProduct(uid,page) {
  return axios.get(`/my_bookmark/product/${uid}?page=${page}`);
}

export function _myMessages(uid,page,size) {
  return axios.get(`/oup_comment/${uid}?page=${page}&size=${size}`);
}

export async function _getCertificateDetail(id) {
  return await axios.get(`/certitficate_detail/${id}`);
}

export function _baiduKey() {
  return axios.get("/oup_user/setting/oup_user.baidu_statistic.settings");
}


