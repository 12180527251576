import axios from "axios";
import store from "@/store";
import * as actions from "@/store/actions";
import _ from "lodash";
import { Modal } from "antd-mobile";
import intl from "react-intl-universal";
import {
  _authorizationWeChatSettings,
  // _authorizationUserInfo,
  _authorizationWeChatAuthorization,
  _authorizationToken,
  _authorizationTemp
} from "@/apis";

// create axios instance

let totalR = 0;

const require = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_DOMAIN
});
let isRefreshing = false;
const refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token) {
  console.log('refreshSubscribers222', refreshSubscribers);
  refreshSubscribers.map(cb => cb(token));
}

const handleWechatAuth = async () => {
  const weChatAuthTime = parseInt(sessionStorage.getItem("weChatAuthTime"));
  sessionStorage.setItem("weChatAuthTime", weChatAuthTime + 1);
  localStorage.setItem("requestFailureTime", Date.parse(new Date()));
  let currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.hash}`;
  let wechatSettings = sessionStorage.getItem('wechatSettings');
  if (!!wechatSettings) {
    wechatSettings = JSON.parse(wechatSettings);
  } else {
    await handleGetWeChatSettings();
    wechatSettings = sessionStorage.getItem('wechatSettings');
    wechatSettings = JSON.parse(wechatSettings);
  }

  localStorage.removeItem("oupToken");
  localStorage.removeItem("oupRefreshToken");
  localStorage.removeItem("oupDrupalId");
  localStorage.removeItem("oupUserRegisterStatus");
  window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatSettings.oup_wechat_app_id
    }&redirect_uri=${encodeURIComponent(
      currentUrl,
      "UTF-8"
    )}&response_type=code&scope=snsapi_userinfo&state=WECHAT&connect_redirect=1#wechat_redirect`;
};

// simulation on
const test = async openID => {
  await _authorizationTemp(openID).then(res => {
    localStorage.setItem("oupToken", res.data.access_token);
    localStorage.setItem("oupRefreshToken", res.data.refresh_token);
  });
};

const handleGetWeChatSettings = async () => {
  const weChatAuthTime = parseInt(
    sessionStorage.getItem("weChatAuthTime")
  );
  if (weChatAuthTime >= 5) {
    sessionStorage.setItem("weChatAuthTime", 0)
  }
  await _authorizationWeChatSettings().then(res => {
    store.dispatch(actions.getWeChatSettings(res.data));
    sessionStorage.setItem('wechatSettings', JSON.stringify(res.data));
  });
};
// Get browser parameters
const getQueryVariable = (variable) => {
  var index = window.location.href.indexOf("?");
  var query = window.location.href.substr(index + 1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      var pairIndex = pair[1].indexOf("#");
      var pairQuery = pair[1].substring(0, pairIndex);
      return pairIndex !== -1 ? pairQuery : pair[1];
    }
  }
  return false;
}

const openid = getQueryVariable("openid");

const getToken = async (drupalCode, isRefreshToken = false) => {
  let wechatSettings = sessionStorage.getItem('wechatSettings');
  if (!!wechatSettings) {
    wechatSettings = JSON.parse(wechatSettings);
  } else {
    await handleGetWeChatSettings();
    wechatSettings = sessionStorage.getItem('wechatSettings');
    wechatSettings = JSON.parse(wechatSettings);
  }

  let params = new FormData();
  params.append("client_id", wechatSettings.oup_client_id);
  params.append(
    "client_secret",
    wechatSettings.oup_client_secret
  );
  if (isRefreshToken) {
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", localStorage.getItem("oupRefreshToken"));
  } else {
    params.append("grant_type", "authorization_code");
    params.append("redirect_uri", "");
    params.append("code", drupalCode);
  }
  await _authorizationToken(params)
    .then(res => {
      localStorage.setItem("oupToken", res.data.access_token);
      localStorage.setItem("oupRefreshToken", res.data.refresh_token);
    })
    .catch(async err => {
      if (err) {
        if (err.status === 401 || err.response.status === 401) {
          if (!!openid) {
            localStorage.removeItem("oupToken");
            test(openid)
          } else {
            handleWechatAuth();
          }
        }
      }
    });
};

// Gets token request judgment
const handleGetToken = async () => {
  let refreshToken = localStorage.getItem("oupRefreshToken");
  if (refreshToken === null || refreshToken === "") {
    const code = getQueryVariable("code");
    if (!!code) {
      await getWechatAuthentication(code);
    } else {
      await handleWechatAuth();
    }
  } else {
    // Refresh token for token
    await getToken("", true);
  }
};

// Exchange WeChat code for drupal code
const getWechatAuthentication = async (code) => {
  let wechatSettings = sessionStorage.getItem('wechatSettings');
  if (!!wechatSettings) {
    wechatSettings = JSON.parse(wechatSettings);
  } else {
    await handleGetWeChatSettings();
    wechatSettings = sessionStorage.getItem('wechatSettings');
    wechatSettings = JSON.parse(wechatSettings);
  }

  let params = {
    app_id: wechatSettings.oup_wechat_app_id,
    code: code,
    client_id: wechatSettings.oup_client_id
  };
  await _authorizationWeChatAuthorization(params).then(res => {
    localStorage.setItem("oupDrupalId", res.data.user_info.openid);
    getToken(res.data.authorization.code);
  });
};

let timer = null;
let isError = false;
const alert = Modal.alert;

// axios request interceptor
require.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("oupToken")) {
      if(!config.url.endsWith("oup_wechat.settings") && !config.url.endsWith("oauth/token")){
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "oupToken"
        )}`;
      }
    }
    config.headers["Content-Type"] = `application/json`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// axios response interceptor
require.interceptors.response.use(
  async response => {
    const omitted = _.get(response.data, "meta.omitted", "no-omitted");
    if (omitted !== "no-omitted") {
      if (!!openid) {
        localStorage.removeItem("oupToken");
        test(openid);
      } else {
        handleGetToken();
      }
    }

    return response;
  },
  async (error) => {
    // handle request error
    const { code, message, config } = error;
    const originalRequest = config;

    if (code === "ECONNABORTED" && message.indexOf("timeout") !== -1) {
      // The request timeout
      return new Promise(_ => {
        if (!isError) {
          const alertInstance = alert("", intl.get("SYSTEM_BUSY"), [
            {
              text: intl.get("MAKE_SURE"),
              onPress: async () => {
                if (!!timer) {
                  clearTimeout(timer);
                }
                if (!!openid) {
                  localStorage.removeItem("oupToken");
                  await test(openid);
                  alertInstance.close();
                } else {
                  handleWechatAuth();
                }
              }
            }
          ]);

          isError = true;
          if (!!timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(async () => {
            if (!!openid) {
              localStorage.removeItem("oupToken");
              test(openid);
            } else {
              handleWechatAuth();
            }
          }, 3000);
        }
      });
    }

    if (error ) {
      if (error.status === 401 || error.response.status === 401 || error.response.status === 403) {

        const retryOrigReq = new Promise((resolve) => {
          subscribeTokenRefresh(() => {
            resolve(require(originalRequest));
          });
        });
        if(error.config.url && error.config.url.endsWith("oauth/token")){
          handleWechatAuth()
        }else if (!isRefreshing) {
          isRefreshing = true;
          if (!!openid) {
            localStorage.removeItem("oupToken");
            await test(openid);
          } else {
            await handleGetToken();
          }

          isRefreshing = false;
          if (totalR < 3) {
            totalR += 1;
            onRefreshed();
          } else {
            await handleGetToken();
          }
        }

        return retryOrigReq;
      }

      if (error.response.status === 404) {
        window.location.replace(
          `${window.location.protocol}//${window.location.host}${window.location.pathname}#/404`
        );
      }
    }

    return Promise.reject(error);
  },
);


export default require;
