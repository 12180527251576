/**
 * Skeleton screen ui.
 */
import React from "react";
import ContentLoader from "react-content-loader";

export const SearchBarLoader = () => (
  <ContentLoader width="375" height="45">
    <rect x="23" y="8" rx="15" ry="15" width="329" height="29" />
  </ContentLoader>
);

export const NavButtonLoader = () => (
  <ContentLoader width="375" height="80">
    <rect x="25" y="23" rx="20" ry="20" width="152" height="40" />
    <rect x="198" y="23" rx="20" ry="20" width="152" height="40" />
  </ContentLoader>
);

export const KvLoader = () => (
  <ContentLoader width="375" height="428">
    <rect x="0" y="0" rx="5" ry="5" width="375" height="430" />
  </ContentLoader>
);

export const TabsLoader = () => (
  <ContentLoader width="375" height="43">
    <rect x="30" y="12" rx="0" ry="0" width="56" height="19" />
    <rect x="126" y="12" rx="0" ry="0" width="56" height="19" />
    <rect x="212" y="12" rx="0" ry="0" width="56" height="19" />
    <rect x="298" y="12" rx="0" ry="0" width="56" height="19" />
  </ContentLoader>
);

export const ProductLoader = () => (
  // hide age
  <ContentLoader width="375" height="210" primaryColor="#f3f3f3">
    <rect x="27" y="10" rx="5" ry="5" width="89" height="125" />
    <rect x="143" y="10" rx="5" ry="5" width="89" height="125" />
    <rect x="259" y="10" rx="5" ry="5" width="89" height="125" />

    <rect x="27" y="151" rx="0" ry="0" width="89" height="8" />
    <rect x="143" y="151" rx="0" ry="0" width="89" height="8" />
    <rect x="259" y="151" rx="0" ry="0" width="89" height="8" />

    <rect x="27" y="171" rx="0" ry="0" width="39" height="8" />
    <rect x="143" y="171" rx="0" ry="0" width="39" height="8" />
    <rect x="259" y="171" rx="0" ry="0" width="39" height="8" />
  </ContentLoader>
);

export const SolutionsListLoader = () => (
  <ContentLoader width="375" height="686">
    <rect x="0" y="0" rx="3" ry="3" width="375" height="343" />
  </ContentLoader>
);

export const SolutionsBannerLoader = () => (
  <ContentLoader width="375" height="180">
    <rect x="0" y="0" rx="0" ry="0" width="375" height="180" />
  </ContentLoader>
);

export const FormBannerLoader = () => (
  <ContentLoader width="375" height="232">
    <rect x="0" y="0" rx="0" ry="0" width="375" height="232" />
  </ContentLoader>
);

export const SearchItemLoader = () => (
  <ContentLoader width="375" height="266" primaryColor="#f3f3f3">
    <rect x="47" y="30" rx="5" ry="5" width="94" height="134" />
    <rect x="226" y="30" rx="5" ry="5" width="94" height="134" />

    <rect x="74" y="180" rx="0" ry="0" width="40" height="8" />
    <rect x="253" y="180" rx="0" ry="0" width="40" height="8" />

    <rect x="47" y="196" rx="0" ry="0" width="94" height="8" />
    <rect x="226" y="196" rx="0" ry="0" width="94" height="8" />

    <rect x="47" y="212" rx="0" ry="0" width="94" height="8" />
    <rect x="226" y="212" rx="0" ry="0" width="94" height="8" />
  </ContentLoader>
);

export const EducationReportDetailBannerLoader = () => (
  <ContentLoader width="375" height="333">
    <rect x="0" y="0" rx="0" ry="0" width="375" height="333" />
  </ContentLoader>
);

export const HistoryBannerLoader = () => (
  <ContentLoader width="375" height="333" primaryColor="#f3f3f3">
    <rect x="0" y="0" rx="0" ry="0" width="375" height="333" />
  </ContentLoader>
);
export const HistoryYearLoader = () => (
  <ContentLoader width="375" height="96" primaryColor="#f3f3f3">
    <rect x="35" y="30" rx="0" ry="0" width="43" height="18" />
    <rect x="222.5" y="30" rx="0" ry="0" width="50" height="18" />
    <rect x="35" y="66" rx="0" ry="0" width="117" height="30" />
    <rect x="222.5" y="66" rx="0" ry="0" width="118" height="30" />
  </ContentLoader>
);
export const HistoryInfoLoader = () => (
  <ContentLoader width="375" height="200" primaryColor="#f3f3f3">
    <rect x="35" y="81" rx="0" ry="0" width="306" height="13" />
    <rect x="35" y="103" rx="0" ry="0" width="306" height="13" />
    <rect x="35" y="125" rx="0" ry="0" width="306" height="13" />
    <rect x="35" y="147" rx="0" ry="0" width="306" height="13" />
    <rect x="35" y="169" rx="0" ry="0" width="306" height="13" />
  </ContentLoader>
);

export const ListBannerLoader = () => (
  //Use to join oup and contact oup page
  <ContentLoader width="375" height="180" primaryColor="#f3f3f3">
    <rect x="0" y="0" rx="0" ry="0" width="375" height="180" />
  </ContentLoader>
);
export const JoinOupOpenPositionLoader = () => (
  <ContentLoader width="375" height="54" primaryColor="#f3f3f3">
    <rect x="23" y="35" rx="0" ry="0" width="56" height="24" />
  </ContentLoader>
);

export const JoinOupListLoader = () => (
  <ContentLoader width="375" height="145" primaryColor="#f3f3f3">
    <rect x="20" y="30" rx="0" ry="0" width="335" height="44" />
    <rect x="20" y="75" rx="0" ry="0" width="335" height="87" />
  </ContentLoader>
);

export const ContactOupFirstlistLoader = () => (
  <ContentLoader width="375" height="205" primaryColor="#f3f3f3">
    <rect x="25" y="40" rx="0" ry="0" width="325" height="165" />
  </ContentLoader>
);

export const ContactOupSecondListLoader = () => (
  <ContentLoader width="375" height="215" primaryColor="#f3f3f3">
    <rect x="25" y="30" rx="0" ry="0" width="325" height="165" />
  </ContentLoader>
);

export const UserCenterInfoLoader = () => (
  <ContentLoader width="375" height="125" primaryColor="#f3f3f3">
    <rect x="0" y="32.5" rx="100" ry="100" width="60" height="60" />
    <rect x="76" y="37" rx="0" ry="0" width="75" height="22" />
    <rect x="76" y="70" rx="0" ry="0" width="150" height="16" />
  </ContentLoader>
);
export const UserCenterActionLoader = () => (
  <ContentLoader width="375" height="51" primaryColor="#f3f3f3">
    <rect x="0" y="0" rx="0" ry="0" width="375" height="50" />
  </ContentLoader>
);

export const MyRedeemLoader = () => (
  <ContentLoader width="375" height="125" primaryColor="#f3f3f3">
    <rect x="0" y="28" rx="0" ry="0" width="60" height="85" />
    <rect x="76" y="30" rx="0" ry="0" width="200" height="12" />
    <rect x="76" y="50" rx="0" ry="0" width="75" height="8" />
    <rect x="76" y="102" rx="0" ry="0" width="100" height="8" />
  </ContentLoader>
);

export const MediaGroupInfoLoader = () => (
  <ContentLoader width="375" height="255" primaryColor="#f3f3f3">
    <rect x="0" y="0" rx="2" ry="2" width="110" height="155" />
    <rect x="130" y="0" rx="0" ry="0" width="220" height="14" />
    <rect x="130" y="26" rx="0" ry="0" width="120" height="14" />
    <rect x="130" y="142" rx="0" ry="0" width="100" height="12" />

    <rect x="0" y="200" rx="0" ry="0" width="130" height="14" />
    <rect x="0" y="230" rx="0" ry="0" width="300" height="12" />
  </ContentLoader>
);

export const MediaGroupAssetsListLoader = () => (
  <ContentLoader width="375" height="125" primaryColor="#f3f3f3">
    <rect x="30" y="28" rx="18" ry="18" width="18" height="18" />
    <rect x="60" y="31" rx="0" ry="0" width="150" height="10" />
    <rect x="320" y="31" rx="0" ry="0" width="25" height="10" />

    <rect x="30" y="65" rx="18" ry="18" width="18" height="18" />
    <rect x="60" y="68" rx="0" ry="0" width="150" height="10" />
    <rect x="320" y="68" rx="0" ry="0" width="25" height="10" />

    <rect x="30" y="102" rx="18" ry="18" width="18" height="18" />
    <rect x="60" y="105" rx="0" ry="0" width="150" height="10" />
    <rect x="320" y="105" rx="0" ry="0" width="25" height="10" />
  </ContentLoader>
);

export const AssetsAudioInformationLoader = () => (
  <ContentLoader width="375" height="268" primaryColor="#f3f3f3">
    <rect x="126" y="0" rx="2" ry="2" width="123" height="172" />
    <rect x="118.5" y="199" rx="0" ry="0" width="138" height="14" />
    <rect x="84" y="228" rx="0" ry="0" width="207" height="12" />
  </ContentLoader>
);

export const AssetsDescriptionLoader = () => (
  <ContentLoader width="375" height="100" primaryColor="#f3f3f3">
    <rect x="30" y="32" rx="0" ry="0" width="80" height="14" />
    <rect x="30" y="55" rx="0" ry="0" width="200" height="12" />
  </ContentLoader>
);

export const MediaAudioPlayerLoader = () => (
  <ContentLoader width="375" height="135" primaryColor="#f3f3f3">
    <rect x="30" y="28" rx="0" ry="0" width="30" height="12" />
    <rect x="80" y="28" rx="6" ry="6" width="210" height="12" />
    <rect x="320" y="28" rx="0" ry="0" width="30" height="12" />

    <rect x="68" y="75" rx="18" ry="18" width="36" height="36" />
    <rect x="162" y="68" rx="25" ry="25" width="50" height="50" />
    <rect x="269" y="75" rx="18" ry="18" width="36" height="36" />
  </ContentLoader>
);

export const AssetsInformationLoader = () => (
  <ContentLoader width="375" height="118" primaryColor="#f3f3f3">
    <rect x="25" y="15" rx="0" ry="0" width="60" height="85" />
    <rect x="100" y="15" rx="0" ry="0" width="210" height="14" />
    <rect x="100" y="42" rx="0" ry="0" width="60" height="12" />
    <rect x="100" y="88" rx="0" ry="0" width="88" height="12" />
  </ContentLoader>
);

export const MyMessagesListLoader = () => (
  <ContentLoader width="325" height="135" primaryColor="#f3f3f3">
    <rect x="0" y="20" rx="0" ry="0" width="325" height="135" />
  </ContentLoader>
);

export const MyEventsLoader = () => (
  <ContentLoader width="375" height="125" primaryColor="#f3f3f3">
    <rect x="15" y="18" rx="0" ry="0" width="78" height="66" />
    <rect x="110" y="18" rx="0" ry="0" width="240" height="20" />
    <rect x="110" y="48" rx="0" ry="0" width="240" height="16" />
    <rect x="110" y="68" rx="0" ry="0" width="240" height="16" />
  </ContentLoader>
);

export const MyCertificatesLoader = () => (
  <ContentLoader width="375" height="99" primaryColor="#f3f3f3">
    <rect x="20" y="0" rx="0" ry="0" width="325" height="40" />
    <rect x="20" y="47" rx="0" ry="0" width="325" height="20" />
  </ContentLoader>
);
