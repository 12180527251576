/**
 * A component for controls the UI that displays before the page loads.
 */
import React from "react";
import Loadable from "react-loadable";

// Universal cutscene component
const loadingComponent = () => {
  return <div></div>;
};

// The cutscene component is generic by default，
// If loading component is passed in, the loading cut-field component is adopted.
export default (loader, loading = loadingComponent) => {
  return Loadable({
    loader,
    loading
  });
};
