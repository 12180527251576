/**
 * React routing file.
 */
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import store from "@/store";
import * as actions from "@/store/actions";
import BottomNavigation from "@/components/BottomNavigation/BottomNavigation";
import ContactUsBottomNavigation from "@/components/ContactUsBottomNavigation";
import { SearchBarLoader } from "@/components/Skeleton/Skeleton";
import Footer from "@/components/Footer/Footer";
// Load the page component as needed
import Loadable from "@/components/Loadable.js";
// Import page component

const Header = Loadable(
  () => import("@/components/Header/Header"),
  SearchBarLoader
);


const ProductsCenter = Loadable(() =>
  import("@/views/productsCenter/productsCenter")
);
const Certificates = Loadable(() =>
  import("@/views/certificates/certificates")
);

const CertificateDetail = Loadable(() =>
  import("@/views/certificates/certificateDetail")
);

const EventsList = Loadable(() => import("@/views/events/list"));
const EventsApply = Loadable(() => import("@/views/events/Apply"));
const EventsDetails = Loadable(() => import("@/views/events/details"));
const ContactUs = Loadable(() => import("@/views/contactUs/contactUs"));
const UserCenter = Loadable(() => import("@/views/user/userCenter/userCenter"));
const MediaCenter = Loadable(() => import("@/views/media/media"));
const EducationResourcesList = Loadable(() =>
  import("@/views/educationResources/list")
);
const EducationResourcesDetails = Loadable(() =>
  import("@/views/educationResources/details")
);
const SolutionsList = Loadable(() => import("@/views/solutions/list"));
const SolutionsDetail = Loadable(() => import("@/views/solutions/detail"));
const Registration = Loadable(() =>
  import("@/views/registration/registration")
);
const EducationReport = Loadable(() => import("@/views/educationReport/list"));
const EducationReportDetail = Loadable(() =>
  import("@/views/educationReport/details")
);
const History = Loadable(() => import("@/views/history/history"));
const SamplePDF = Loadable(() => import("@/views/sample/pdf"));
const SampleVideo = Loadable(() => import("@/views/sample/video"));
const SampleAudio = Loadable(() => import("@/views/sample/audio"));
const ContactOup = Loadable(() => import("@/views/contactOup/contactOup"));
const NotFound = Loadable(() => import("@/views/notFound/notFound"));
const SearchResults = Loadable(() =>
  import("@/views/searchResults/searchResults")
);
const SupportingResources = Loadable(() =>
  import("@/views/supportingResources/details")
);
const JoinOupList = Loadable(() => import("@/views/joinOup/list"));
const JoinOupDetails = Loadable(() => import("@/views/joinOup/details"));
const AccountSettings = Loadable(() =>
  import("@/views/user/accountSettings/accountSettings")
);
const MediaGroup = Loadable(() => import("@/views/media/group"));
const MyRedeem = Loadable(() => import("@/views/user/myRedeem/myRedeem"));
const MyCertificates = Loadable(() => import("@/views/user/myCertificates/myCertificates"));
const MyBookmarks = Loadable(() =>
  import("@/views/user/myBookmarks/myBookmarks")
);
const MyEvents = Loadable(() =>
  import("@/views/user/myEvents/myEvents")
);
const MyMessages = Loadable(() => import("@/views/user/myMessages/myMessages"));
const HomeLearningList = Loadable(() => import("@/views/homeLearning/list"));
const HomeLearningDetails = Loadable(() =>
  import("@/views/homeLearning/details")
);

const AssetDetails = Loadable(() => import("@/views/media/resources/assets"));
const DownloadInstruction = Loadable(() =>
  import("@/views/media/downloadInstruction/downloadInstruction")
);

const TmallShop = Loadable(() => import("@/views/tmallshop/tmallshop"));

const OpenBrowser = Loadable(() => import("@/views/openBrowser/openBrowser"));

const Answer = Loadable(() => import("@/views/answer/answer"));
const Quize = Loadable(() => import("@/views/answer/quize"));
const Recommend = Loadable(() => import("@/views/answer/recommend"));

class Routers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      baiduKey: ""
    };
  }
  // Get browser parameters
  getQueryVariable(variable) {
    var index = window.location.href.indexOf("?");
    var query = window.location.href.substr(index + 1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        var pairIndex = pair[1].indexOf("#");
        var pairQuery = pair[1].substring(0, pairIndex);
        return pairIndex !== -1 ? pairQuery : pair[1];
      }
    }
    return false;
  }
  componentDidMount() {
    this.isMount = true;
  }
  componentWillUnmount() {
    this.isMount = false;
  }
  /** Fires before redirecting to a route **/
  onEnter(Component, props, isBottomNavigation = null, isHeader = null, searchConfig = {
    hasSearch: false, searchType: ''
  }) {
    window._hmt.push(["_trackPageview", props.location.pathname]);
    window.scrollTo(0, 0);
    const openid = this.getQueryVariable("openid");
    if (window.location.search !== "" && !openid) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.hash}`
      );
    }
    if (!sessionStorage.getItem("firstEnterUrl")) {
      sessionStorage.setItem(
        "firstEnterUrl",
        `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.hash}`
      );
    }
    store.dispatch(actions.setSearchConfig(searchConfig));

    const isFromMenuEnter = isBottomNavigation === "ContactUsBottomNavigation";

    return (
      <div>
        {
          (!window.location.hash.includes("/tmallshop")
          && isHeader !== 'hide') ? <Header searchConfig={searchConfig} isFromContactEnter={isFromMenuEnter} /> : null
        }

        <Component {...props} />

        {isBottomNavigation !== "DetailToolBar" ? <Footer /> : null}
        {isBottomNavigation === "BottomNavigation" ? (
          <BottomNavigation />
        ) : null}
        {isFromMenuEnter ? <ContactUsBottomNavigation />: null}
      </div>
    );
  }
  render() {
    return (
      <Switch>
        <Redirect exact path="/" to={{ pathname: "/products-center" }} />
        <Route
          exact
          path="/products-center"
          render={props => this.onEnter(ProductsCenter,  props, "BottomNavigation", null, {
            hasSearch: true, searchType: 'product'
          })}
        />
        <Route
          exact
          path="/answer-start"
          render={props => this.onEnter(Answer, props, "DetailToolBar", 'hide')}
        />
        <Route
          exact
          path="/answer-progress"
          render={props => this.onEnter(Quize, props, "DetailToolBar", "hide")}
        />
        <Route
          exact
          path="/answer-result"
          render={props => this.onEnter(Recommend, props, BottomNavigation, "hide")}
        />
        <Route
          exact
          path="/certificate-exchange"
          render={props => this.onEnter(Certificates,  props, "BottomNavigation")}
        />
        <Route
          exact
          path="/certificateDetail/:id"
          render={props => this.onEnter(CertificateDetail,  props)}
        />
        <Route
          exact
          path="/products-center/:firstmenu/:secondmenu"
          render={props =>
            this.onEnter(ProductsCenter, props, "BottomNavigation", null, {
              hasSearch: true, searchType: 'product'
          })
          }
        />
        <Route
          exact
          path="/events"
          render={props => this.onEnter(EventsList, props, "BottomNavigation", null, {
            hasSearch: true, searchType: "upcoming_event"
          })}
        />
        <Route
          exact
          path="/events/apply"
          render={(props) =>
            this.onEnter(EventsApply, props, "BottomNavigation", null, {
            hasSearch: true, searchType: "upcoming_event"
          })
          }
        />
        <Route
          exact
          path="/events/:type/details/:id"
          render={props =>
            this.onEnter(EventsDetails, props, "BottomNavigation", null, {
            hasSearch: true, searchType: "upcoming_event"
          })
          }
        />
        <Route
          exact
          path="/solutions"
          render={props =>
            this.onEnter(SolutionsList, props, "BottomNavigation", null, {
            hasSearch: true, searchType: 'product'
          })
          }
        />
        <Route
          exact
          path="/education-resources"
          render={props =>
            this.onEnter(EducationResourcesList, props, "BottomNavigation", null, {
            hasSearch: true, searchType: 'product'
          })
          }
        />
        <Route
          exact
          path="/education-resources/details/:id"
          render={props =>
            this.onEnter(EducationResourcesDetails, props, "DetailToolBar", null, {
            hasSearch: true, searchType: 'product'
          })
          }
        />
        <Route
          exact
          path="/education-resources/:firstmenu/:secondmenu"
          render={props =>
            this.onEnter(EducationResourcesList, props, "BottomNavigation", null, {
            hasSearch: true, searchType: 'product'
          })
          }
        />
        <Route
          exact
          path="/solutions/details/:id"
          render={props =>
            this.onEnter(SolutionsDetail, props, "DetailToolBar", null, {
            hasSearch: true, searchType: 'product'
          })
          }
        />
        <Route
          exact
          path="/contact-us/:formPage"
          render={props => this.onEnter(ContactUs, props, "BottomNavigation")}
        />
        <Route
          exact
          path="/user-center-from-menu-enter"
          render={props => this.onEnter(UserCenter, props, 'ContactUsBottomNavigation')}
        />
        <Route
          exact
          path="/user-center"
          render={props => this.onEnter(UserCenter, props, "BottomNavigation")}
        />
        <Route
          exact
          path="/user-center/settings"
          render={props => this.onEnter(AccountSettings, props)}
        />
        <Route
          exact
          path="/user-center/my-events"
          render={props => this.onEnter(MyEvents, props)}
        />
        <Route
          exact
          path="/user-center/my-bookmarks/:id/:tabs"
          render={props => this.onEnter(MyBookmarks, props)}
        />
        <Route
          exact
          path="/user-center/my-messages/:id"
          render={props => this.onEnter(MyMessages, props)}
        />
        <Route
          exact
          path="/media-center"
          render={props => this.onEnter(MediaCenter, props, "BottomNavigation")}
        />
        <Route
          exact
          path="/registration"
          render={props =>
            this.onEnter(Registration, props, "BottomNavigation")
          }
        />
        <Route
          exact
          path="/education-report"
          render={props => this.onEnter(EducationReport, props)}
        />
        <Route
          exact
          path="/education-report/details/:id"
          render={props => this.onEnter(EducationReportDetail, props)}
        />
        <Route
          exact
          path="/history"
          render={props => this.onEnter(History, props, "ContactUsBottomNavigation")}
        />
        <Route
          exact
          path="/sample-pdf/:from/:id/:detailId/:contentId"
          render={props => this.onEnter(SamplePDF, props)}
        />
        <Route
          exact
          path="/sample-video/:from/:id/:detailId/:contentId"
          render={props => this.onEnter(SampleVideo, props)}
        />
        <Route
          exact
          path="/sample-audio/:from/:id/:detailId/:contentId/:currIndex"
          render={props => this.onEnter(SampleAudio, props)}
        />
        <Route
          exact
          path="/contact-oup"
          render={props => this.onEnter(ContactOup, props)}
        />
        <Route
          exact
          path="/contact-oup-from-menu-enter"
          render={props => this.onEnter(ContactOup, props, "ContactUsBottomNavigation")}
        />
        <Route
          exact
          path="/search/:searchType/:id"
          render={props => this.onEnter(SearchResults, props, null, null, {
            hasSearch: true, searchType: ''
          })}
        />
        <Route
          exact
          path="/supporting-resources/:id"
          render={props => this.onEnter(SupportingResources, props)}
        />
        <Route
          exact
          path="/join"
          render={props => this.onEnter(JoinOupList, props, null, null, {
            hasSearch: true, searchType: 'officer'
          })}
        />
        <Route
          exact
          path="/join/details/:id"
          render={props => this.onEnter(JoinOupDetails, props, null, null, {
            hasSearch: true, searchType: 'officer'
          })}
        />
        <Route
          exact
          path="/media-center/group/:groupId/:codeId/:showSuccess"
          render={props => this.onEnter(MediaGroup, props)}
        />
        <Route
          exact
          path="/user-center/my-redeem"
          render={props => this.onEnter(MyRedeem, props)}
        />
        <Route
          exact
          path="/user-center/my-certificates"
          render={props => this.onEnter(MyCertificates, props)}
        />
        <Route
          exact
          path="/home-learning/:tabs"
          render={props => this.onEnter(HomeLearningList, props, null, null, {
            hasSearch: true, searchType: 'learning_home'
          })}
        />
        <Route
          exact
          path="/home-learning/details/:id/:nid"
          render={props => this.onEnter(HomeLearningDetails, props, null, null, {
            hasSearch: true, searchType: 'learning_home'
          })}
        />
        <Route
          exact
          path="/media-center/:type/:groupId/:id/:codeId/:currIndex"
          render={props => this.onEnter(AssetDetails, props)}
        />
        <Route
          exact
          path="/download-instruction"
          render={props => this.onEnter(DownloadInstruction, props)}
        />
        <Route
          exact
          path="/tmallshop"
          component={TmallShop}
        />
        <Route
          exact
          path="/openbrowser"
          render={props => this.onEnter(OpenBrowser, props)}
        />
        <Route
          render={props => this.onEnter(NotFound, props, "BottomNavigation")}
        />

      </Switch>
    );
  }
}

export default Routers;
