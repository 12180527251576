import axios from "../axios";

export async function _getMyCertificatesListAPI(pageIndex) {
  return await axios.get('/my_certificate');
}

export async function _certificateSearch(params) {
  return await axios.get(`/certificate/${params.certificateID}?user_name=${params.name}`);
}

export async function _getCertificateDetail(id) {
  return await axios.get(`/certificate/${id}`);
}

export async function _bindCertificate(id) {
  return await axios.get(`/certificate/${id}/bind`);
}

export async function _unbindCertificate(id) {
  return await axios.get(`/certificate/${id}/unbind`);
}

export async function _certificateDownload(id) {
  return await axios.get(`/certificate/download/${id}`);
}
