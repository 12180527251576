import axios from "../axios";

export async function _authorizationWeChatSettings() {
  return await axios.get("/oup_user/setting/oup_wechat.settings");
}

export async function _authorizationUserInfo() {
  return await axios.get("/oup_user/info");
}

export async function _authorizationWeChatAuthorization(params) {
  return await axios.post("/wechat/authentication", params);
}

export async function _authorizationToken(params) {
  return await axios.post("/oauth/token", params);
}

export async function _authorizationWeChatShare(params) {
  return await axios.post("/wechat/share", params);
}

export async function _authorizationTemp(openid) {
  return await axios.get(`/oup_user/token/${openid}`);
}
