import axios from "../axios";

export async function _getQuizes() {
  return await axios.get(
    "/jsonapi/taxonomy_term/pd_solution_question?include=field_options&filter[status][value]=1&jsonapi_include=1&sort=weight"
  );
}

export async function _quizesFinish(params) {
  return await axios.post("/oup-pd-solution/recommend" , params);
}