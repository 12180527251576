import axios from "../axios";

export async function _blockCopyRight() {
  return await axios.get(
    "/jsonapi/block_content/simple_block?filter[field_position]=0&fields[block_content--simple_block]=body"
  );
}

export async function _blockContactOup() {
  return await axios.get(
    "jsonapi/node/contact_us?jsonapi_include=1&include=field_paragraph,field_banner,field_paragraph.field_background_image,field_paragraph.field_name&fields[file--file]=uri&page[limit]=1&sort=created"
  );
}

export async function _blockHistory() {
  return await axios.get(
    "/jsonapi/node/page?filter[field_position]=%E7%89%9B%E6%B4%A5%E5%8E%86%E5%8F%B2"
  );
}

export async function _blockDownloadInstructiony() {
  return await axios.get(
    "/jsonapi/node/page?filter[field_position]=%E4%B8%8B%E8%BD%BD%E6%8C%87%E5%AF%BC"
  );
}

