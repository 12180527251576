import axios from "../axios";

export async function _searchHotSearchKeyWords() {
  return await axios.get(
    "/jsonapi/node/search_keywords?sort=field_weight,-field_search_times&page[limit]=5"
  );
}

export async function _searchResultData(params) {
  return await axios.post("/oup_search/search_result", params);
}
