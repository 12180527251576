import axios from "../axios";

export function _detailsEducationReport(id) {
  return axios.get(
    `/jsonapi/node/education_report/${id}?include=field_paragraph,field_paragraph.field_background_image,field_paragraph.field_image,field_paragraph.field_carousel_images,field_paragraph.field_video_preview_image,field_education_report,field_banner,field_advertisement.field_image,field_paragraph.field_video&fields[file--file]=uri,filesize,filename&fields[node--education_report]=field_paragraph,title,field_education_report,field_banner,field_advertisement,field_baidustatistics&jsonapi_include=1&fields[paragraph--image]=field_image,field_link&fields[paragraph--rich_content]=field_background_image,field_text&fields[paragraph--video]=field_video,field_background_image,field_text,field_video_preview_image&fields[paragraph--carousel]=field_carousel_images,field_background_image&fields[node--advertisement]=field_image,field_link,field_baidustatistics`
  );
}

export function _detailsEducationReportAsset(id) {
  return axios.get(
    `/jsonapi/node/education_report/${id}?include=field_education_report&fields[file--file]=uri,filesize,filename&fields[node--education_report]=title,field_education_report,field_baidustatistics&jsonapi_include=1`
  );
}

export function _detailsEducationResources(id) {
  return axios.get(
    `/jsonapi/node/product/${id}?include=field_advertisement,field_paragraph,field_advertisement,field_advertisement.field_image,field_paragraph.field_background_image,field_paragraph.field_image,field_paragraph.field_carousel_images,field_thumbnail,field_paragraph.field_video_preview_image,field_paragraph.field_video&jsonapi_include=1&fields[node--product]=title,drupal_internal__nid,field_product_category,field_suitable_age,field_thumbnail,field_weight,field_advertisement,field_paragraph,field_show_free_samples&fields[file--file]=uri&fields[node--advertisement]=field_image,field_link,field_baidustatistics`
  );
}

export function _detailsEvents(id) {
  return axios.get(
    `/jsonapi/node/upcoming_event/${id}?include=field_paragraph,field_paragraph.field_background_image,field_paragraph.field_image,field_paragraph.field_carousel_images,field_paragraph.field_video_preview_image,field_advertisement,field_advertisement.field_image,field_paragraph.field_video&jsonapi_include=1&fields[file--file]=uri&fields[node--advertisement]=field_image,field_link,field_baidustatistics`
  );
}

export function _detailsEventsApplyJudgement(params) {
  return axios.post("/oup_event/whether_register", params);
}

// ! To do
export function _oneClickApplyApi(params) {
  return axios.post("/todo", params);
}

export function _detailsSolutions(id) {
  return axios.get(
    `/jsonapi/course_solutions/${id}?include=field_paragraph,field_paragraph.field_background_image,field_paragraph.field_image,field_paragraph.field_carousel_images,field_paragraph.field_video_preview_image,field_advertisement,field_advertisement.field_image,field_paragraph.field_video&fields[file--file]=uri&fields[course_solutions]=field_paragraph,title,field_advertisement,drupal_internal__nid,field_show_free_samples&jsonapi_include=1&fields[paragraph--image]=field_image,field_link&fields[paragraph--rich_content]=field_background_image,field_text&fields[paragraph--video]=field_video,field_background_image,field_text,field_video_preview_image&fields[paragraph--carousel]=field_carousel_images,field_background_image&fields[node--advertisement]=field_image,field_link,field_baidustatistics`
  );
}

export function _detailsSupportingResources(id) {
  return axios.get(
    `/jsonapi/node/support_resources/${id}?include=field_paragraph,field_paragraph.field_background_image,field_paragraph.field_image,field_paragraph.field_carousel_images,field_paragraph.field_video_preview_image,field_advertisement,field_advertisement.field_image,field_paragraph.field_video&fields[file--file]=uri&fields[node--support_resources]=field_paragraph,title,drupal_internal__nid,field_advertisement&jsonapi_include=1&fields[paragraph--image]=field_image,field_link&fields[paragraph--rich_content]=field_background_image,field_text&fields[paragraph--video]=field_video,field_background_image,field_text,field_video_preview_image&fields[paragraph--carousel]=field_carousel_images,field_background_image&fields[node--advertisement]=field_image,field_link,field_baidustatistics`
  );
}

export function _detailsJoinOup(id) {
  return axios.get(
    `jsonapi/node/officer/${id}?include=field_advertisement,field_paragraph,field_advertisement,field_advertisement.field_image,field_paragraph.field_background_image,field_paragraph.field_image,field_paragraph.field_carousel_images,field_paragraph.field_video_preview_image,field_paragraph.field_video&jsonapi_include=1&fields[file--file]=uri`
  );
}

export function _detailsHomeLearning(id) {
  return axios.get(
    `/jsonapi/node/learning_home/${id}?include=field_paragraph,field_paragraph.field_background_image,field_paragraph.field_image,field_paragraph.field_carousel_images,field_paragraph.field_video_preview_image,field_advertisement,field_advertisement.field_image,field_paragraph.field_video&fields[file--file]=uri&fields[course_solutions]=field_paragraph,title,field_advertisement,drupal_internal__nid,field_show_free_samples&jsonapi_include=1&fields[paragraph--image]=field_image,field_link&fields[paragraph--rich_content]=field_background_image,field_text&fields[paragraph--video]=field_video,field_background_image,field_text,field_video_preview_image&fields[paragraph--carousel]=field_carousel_images,field_background_image&fields[node--advertisement]=field_image,field_link,field_baidustatistics`
  );
}

export async function _detailsHomeLearningRead(nid) {
  return await axios.get(`/oup_learning_home/${nid}/info`);
}
