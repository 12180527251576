/**
 * Configure the higher-order components before the page loads.
 */
import React, { Component } from "react";
import store from "@/store";
import * as actions from "@/store/actions";
import Loading from "@/components/Loading/Loading";
import { Modal } from "antd-mobile";
import intl from "react-intl-universal";
import {
  _authorizationWeChatSettings,
  _authorizationUserInfo,
  _authorizationWeChatAuthorization,
  _authorizationToken,
  _authorizationTemp
} from "@/apis";
import Vconsole from "vconsole";

// eslint-disable-next-line
var vConsole =
  process.env.REACT_APP_ENV === "STG" || process.env.REACT_APP_ENV === "PROD"
    ? null
    : new Vconsole();

export default WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        code: "",
        isLoaded: false,
        wechatSettings: {}
      };
    }
    // Get browser parameters
    getQueryVariable(variable) {
      var index = window.location.href.indexOf("?");
      var query = window.location.href.substr(index + 1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) {
          var pairIndex = pair[1].indexOf("#");
          var pairQuery = pair[1].substring(0, pairIndex);
          return pairIndex !== -1 ? pairQuery : pair[1];
        }
      }
      return false;
    }
    // Get background WeChat configuration.
    handleGetWeChatSettings = async () => {
      const weChatAuthTime = parseInt(
        sessionStorage.getItem("weChatAuthTime")
      );
      if (weChatAuthTime >= 5) {
        sessionStorage.setItem("weChatAuthTime", 0)
      }

      await _authorizationWeChatSettings().then(res => {
        if (this.isMount) {
          store.dispatch(actions.getWeChatSettings(res.data));
          sessionStorage.setItem('wechatSettings', JSON.stringify(res.data));
          this.setState({ wechatSettings: res.data });
        }
      });
    };

    // Clean the token of local storage and get the WeChat code again
    handleWechatAuth = () => {
      const weChatAuthTime = Number(sessionStorage.getItem("weChatAuthTime"));
      sessionStorage.setItem("weChatAuthTime", weChatAuthTime + 1);
      localStorage.setItem("requestFailureTime", Date.parse(new Date()));
      let currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.hash}`;
      localStorage.removeItem("oupToken");
      localStorage.removeItem("oupRefreshToken");
      localStorage.removeItem("oupDrupalId");
      localStorage.removeItem("oupUserRegisterStatus");
      window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.state.wechatSettings.oup_wechat_app_id
        }&redirect_uri=${encodeURIComponent(
          currentUrl,
          "UTF-8"
        )}&response_type=code&scope=snsapi_userinfo&state=WECHAT&connect_redirect=1#wechat_redirect`;
    };
    // Get user information
    getUserInfo = async () => {
      await _authorizationUserInfo().then(res => {
        if (this.isMount) {
          store.dispatch(actions.getUserInfo(res.data.user_info));
          localStorage.setItem(
            "oupUserRegisterStatus",
            res.data.user_info.is_register ? 1 : 2
          );
          localStorage.setItem("oupDrupalId", res.data.user_info.uid);
          localStorage.setItem("requestFailureTime", 0);
          this.setState({ isLoaded: true });
          sessionStorage.setItem("weChatAuthTime", 0);
          localStorage.setItem(
            "isComplementInfo",
            res.data.user_info.is_complement_info ? 1 : 2
          );
        }
      });
    };
    // Exchange WeChat code for drupal code
    getWechatAuthentication = async () => {
      let params = {
        app_id: this.state.wechatSettings.oup_wechat_app_id,
        code: this.state.code,
        client_id: this.state.wechatSettings.oup_client_id
      };
      await _authorizationWeChatAuthorization(params).then(res => {
        if (this.isMount) {
          localStorage.setItem("oupDrupalId", res.data.user_info.openid);
          this.getToken(res.data.authorization.code);
        }
      });
    };
    // Get token
    // If there is a refresh token, use the refresh token in exchange for a token
    // The isRefreshToken parameters is true and drupalCode can be empty.
    getToken = async (drupalCode, isRefreshToken = false, callback) => {
      let params = new FormData();
      params.append("client_id", this.state.wechatSettings.oup_client_id);
      params.append(
        "client_secret",
        this.state.wechatSettings.oup_client_secret
      );

      if (isRefreshToken) {
        params.append("grant_type", "refresh_token");
        params.append("refresh_token", localStorage.getItem("oupRefreshToken"));
      } else {
        params.append("grant_type", "authorization_code");
        params.append("redirect_uri", "");
        params.append("code", drupalCode);
      }
      await _authorizationToken(params)
        .then(res => {
          if (this.isMount) {
            localStorage.setItem("oupToken", res.data.access_token);
            localStorage.setItem("oupRefreshToken", res.data.refresh_token);
            this.getUserInfo();
            if (isRefreshToken && !!callback) {
              callback();
            }
          }
        })
        .catch(async err => {
          if (err && err.response) {
            if (err.response.status === 401) {
              const openid = this.getQueryVariable("openid");
              if (!!openid) {
                localStorage.removeItem("oupToken");
                await this.handleGetWeChatSettings();
                this.test(openid);
              } else {
                this.handleWechatAuth();
              }
            }
          }
        });
    };
    // Gets token request judgment
    handleGetToken = callback => {
      let refreshToken = localStorage.getItem("oupRefreshToken");
      if (refreshToken === null || refreshToken === "") {
        const code = this.getQueryVariable("code");
        if (!!code) {
          this.setState({ code: code }, () => {
            this.getWechatAuthentication();
          });
        } else {
          this.handleWechatAuth();
        }
      } else {
        // Refresh token for token
        this.getToken("", true, callback);
      }
    };
    // simulation on
    test = async openid => {
      await _authorizationTemp(openid).then(res => {
        if (this.isMount) {
          localStorage.setItem("oupToken", res.data.access_token);
          localStorage.setItem("oupRefreshToken", res.data.refresh_token);
          this.getUserInfo();
        }
      });
    };
    componentDidMount() {
      this.isMount = true;
    }
    componentWillUnmount() {
      this.isMount = false;
    }
    async UNSAFE_componentWillMount() {
      if (
        window.location.hash.includes("/tmallshop")
        || window.location.hash.includes("/openbrowser?link=")
      ) {
        this.setState({
          isLoaded: true
        });
        return;
      }

      const openid = this.getQueryVariable("openid");
      let token = localStorage.getItem("oupToken");
      let drupalId = localStorage.getItem("oupDrupalId");
      if (!!openid) {
        localStorage.removeItem("oupToken");
        await this.handleGetWeChatSettings();
        await this.test(openid);
      } else {
        // If there is no token, or set the request header and get the user information

        if (!token || !drupalId) {
          const weChatAuthTime = parseInt(
            sessionStorage.getItem("weChatAuthTime")
          );
          const lastRequestFailureTime = parseInt(
            localStorage.getItem("requestFailureTime")
          );
          let nowTimestamp = Date.parse(new Date());
          if (
            weChatAuthTime > 5 &&
            (nowTimestamp - lastRequestFailureTime) / 1000 < 300
          ) {
            Modal.alert("", intl.get("SYSTEM_BUSY"), [
              {
                text: intl.get("MAKE_SURE"),
                onPress: () => {
                  window.location.reload();
                }
              }
            ]);
          } else {
            await this.handleGetWeChatSettings();
            this.handleGetToken()
          }
        } else {
          await this.handleGetWeChatSettings();
          this.getUserInfo();
        }
      }
    }
    render() {
      return (
        <div>
          {this.state.isLoaded ? (
            <div>
              <WrappedComponent {...this.props} />
            </div>
          ) : (
            <Loading desc={intl.get("LOADING")} />
          )}
        </div>
      );
    }
  };
};
